<template>
  <span>
      <h1 class="headline blue--text text-center mt-4 mb-3">Netvisor palkkaraportti</h1>
    <v-row>
      <v-col>
              <v-select
                  v-model="selectedResource"
                  :items="periods"
                  :return-object="false"
                  item-value="id"
                  label="Valitse aikajakso"
              >
          <template #item="{ item }">
            {{ item.start }} - {{ item.end }}
          </template>
          <template #selection="{ item }">
            {{ item.start }} - {{ item.end }}
          </template>
      </v-select>
      </v-col>
      <v-col>
        <v-checkbox v-model="tikon_code" label="Tikon koodi (kytke pois jos haluat nähdä yritykset)"></v-checkbox>
      </v-col>
    </v-row>

      <v-data-table
              class="mt-4"
              :headers="headers"
              :items="salaries"
              :loading="salariesLoading"
              loading-text="Ladataan palkkoja..."
      >
          <template v-slot:top>
            <v-toolbar flat>
              <v-toolbar-title>Palkat valitulla aikajaksolla</v-toolbar-title>
              <v-divider class="mx-4" inset vertical></v-divider>
              <v-spacer></v-spacer>
              <v-btn @click="copyTable" color="green">
                  <v-icon v-if="copied">mdi-check</v-icon>
                  Kopioi taulun tiedot</v-btn>
            </v-toolbar>
          </template>
      </v-data-table>
  </span>
</template>

<script>
export default {
    name: "SalaryReporting",
    data() {
        return {
            headers: [
                {text: 'Palkansaajan id', value: 'agent_code'},
                {text: 'Palkansaajan nimi', value: 'agent_name'},
                {text: 'Alku', value: 'period_start'},
                {text: 'Loppu', value: 'period_end'},
                {text: 'Palkkalajin ID', value: 'paycheck_code'},
                {text: 'Summa', value: 'amount'},
                {text: 'Hinta/kpl', value: 'price_per_one'},
                {text: 'Kuvaus', value: 'definition'},
            ],
            salaries: [],
            salariesLoading: false,
            periods: [],
            selectedResource: null,
            copied: false,
            tikon_code: false,
        };
    },
    watch: {
        selectedResource(){
           this.getResource()
        },
      tikon_code(){
        this.getResources()
      }
    },
    methods: {
        async getResources(){
            await this.axios.get('reports/salary_periods', {
                params:{
                  skip: 0,
                  take: 24,
                }
            }).then((resources) => {
                this.periods = resources.data
                console.log(this.selectedResource)
                this.selectedResource = this.periods[0].id
                console.log(this.selectedResource)
            }).catch ((error) => {
                console.error(error);
            });
            this.getResource()
        },
        async getResource(){
            this.salariesLoading = true
            this.copied = false
            this.salaries = []
            await this.axios.get('reports/netvisor_report', {
                params:{
                    salary_period_id: this.selectedResource,
                    //salary_period_id: 499,
                  tikon_code: this.tikon_code ? '1' : '0',
                }
            }).then((resources) => {
                this.salaries = resources.data
            }).catch ((error) => {
                console.error(error);
            });
            this.salariesLoading = false
        },

        copyTable() {
            const tableText = this.getTableText();

            navigator.clipboard.writeText(tableText)
                .then(() => {
                    console.log('Table copied to clipboard!');
                })
                .catch((error) => {
                    console.error('Failed to copy table to clipboard:', error);
                });
        },
        getTableText() {
            // Create a string representation of the table
            let tableText = '';

            // Add the table data
            this.salaries.forEach(item => {
                //Object.values(item).forEach(value => {
                //    tableText += value + '\t';
                //});
                tableText += item.agent_code + '\t';
                //tableText += item.agent_name + '\t';
                tableText += item.period_start + '\t';
                tableText += item.period_end + '\t';
                tableText += item.paycheck_code + '\t';
                //tableText += item.amount + '\t';
                //tableText += item.price_per_one + '\t';
                tableText += item.amount.toString().replace('.', ',') + '\t';
                tableText += item.price_per_one.toString().replace('.', ',') + '\t';
                if(item.definition) tableText += item.definition + '\t';
                  else tableText += '\t';
                tableText += '\n';
            });
            this.copied = true
            return tableText;
        },
    },
    mounted(){
        this.getResources()
    }
}
</script>

<style scoped>

</style>